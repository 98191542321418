<template>
  <div>
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>

    <img
      class="banner-image w-100"
      v-if="deepGet(form, 'config.confirm_page.banner_image')"
      :src="deepGet(form, 'config.confirm_page.banner_image')"
    />

    <div class="page">
      <div
        v-if="deepGet(form, 'config.confirm_page.form_title')"
        class="page__title"
      >
        {{ deepGet(form, 'config.confirm_page.form_title') }}
      </div>
      <div v-if="deepGet(form, 'config.confirm_page.form_desc')" class="page__desc">
        {{ deepGet(form, 'config.confirm_page.form_desc') }}
      </div>
      <div
        v-if="deepGet(form, 'config.confirm_page.content')"
        v-html="deepGet(form, 'config.confirm_page.content')"
      ></div>
      <SharedButton
        class="s-btn-bg-primary"
        @click="handleConfirm"
        :disabled="isButtonDisabled"
      >
        {{ buttonText }}
      </SharedButton>
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        class="mt-2"
        button-text="回 LINE 官方帳號"
        button-url=""
        type="OA"
      >
      </MemberButton>
    </div>
  </div>
</template>

<script>
import {
  isAfter,
  set,
  getHours,
  getMinutes,
} from "date-fns";
import formApi from "@/apis/liff/v2/form";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";
import deepGet from "@/utils/deepGet";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import { startOfDay, endOfDay } from "date-fns";

export default {
  components: {
    Loading,
    MemberButton,
    SharedButton,
  },
  data() {
    return {
      form: {},
      formResult: {},
      branchCode: null,
      isLoading: true,
    };
  },
  computed: {
    hasStarted() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      let startAt = this.form.start_at
      if (this.form?.config?.confirm_page?.period_type == 'custom') {
        startAt = startOfDay(new Date(this.form.config.confirm_page.period_start_at))
      }

      const startDate = set(new Date(startAt), {
        hours: String(getHours(new Date(startAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(startAt))).padStart(2, "0"),
      });

      return isAfter(today, startDate);
    },
    hasEnded() {
      const today = set(new Date(), {
        hours: String(getHours(new Date())).padStart(2, "0"),
        minutes: String(getMinutes(new Date())).padStart(2, "0"),
      });

      let endAt = this.form.end_at
      if (this.form?.config?.confirm_page?.period_type == 'custom') {
        endAt = endOfDay(new Date(this.form.config.confirm_page.period_end_at))
      }

      const endDate = set(new Date(endAt), {
        hours: String(getHours(new Date(endAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(endAt))).padStart(2, "0"),
      });

      return isAfter(today, endDate);
    },
    isInValidTimeRange() {
      return this.hasStarted && false == this.hasEnded;
    },
    needBranchCode() {
      return deepGet(this.form, "config.confirm_page.confirm_type") === "branch_code";
    },
    buttonText() {
      if (false == this.hasStarted) {
        return "尚未開始"
      }

      if (this.hasEnded) {
        return "已過期"
      }
      return this.formResult.status == 'pending' ? "確認使用" : "已使用";
    },
    isButtonDisabled() {
      return this.formResult.status != 'pending' || (false == this.isInValidTimeRange)
    },
  },
  async mounted() {
    await Promise.all([
      this.fetchForm(),
      this.fetchFormResult(),
    ]);

    if (this.form.status !== 'published') {
      this.$router.replace({ name: "LiffFormIndex", params: { form_id: this.form.id } });
    }

    this.isLoading = false;
  },
  methods: {
    deepGet,
    async fetchForm() {
      try {
        const response = await formApi.getForm(
          this.$route.params.form_id,
          {}
        );
        this.form = response.data.data;
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
    async fetchFormResult() {
      try {
        const response = await formApi.getFormResult(
          this.$route.params.form_id,
          this.$route.query.form_result_id,
        );
        this.formResult = response.data.data;
        this.formResult = response.data.data;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      }
    },
    handleConfirm() {
      if (this.isButtonDisabled) {
        return
      }
      let confirmInput = this.needBranchCode
        ? {
            input: "text",
            inputPlaceholder: "請輸入店號",
            animation: false,
            preConfirm: (value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  reject('店編不存在');
                } else {
                  this.branchCode = value;
                  resolve();
                }
              });
            },
          }
        : {};

      this.$swal
        .fire({
          title: "確定要使用嗎？",
          text: "確認使用後將無法復原",
          confirmButtonText: "確認使用",
          cancelButtonText: "取消",
          showCancelButton: true,
          reverseButtons: true,
          showLoaderOnConfirm: true,
          ...confirmInput,
        })
        .then((result) => {
            if (result.value) {
              this.redeem();
            }
        }).catch(() => {
          this.$swal.fire({ title: "店編不存在", type: "error" });
        });
    },
    async redeem() {
      try {
        this.isLoading = true;
        await formApi.redeem(this.$route.query.form_result_id, {
          branch_code: this.branchCode,
        });
        this.$swal.fire({ title: "核銷成功", type: "success" });
      } catch (error) {
        console.log("error", error);
        this.$swal.fire({ title: "店編不存在", type: "error" });
      } finally {
        this.isLoading = false;
        this.fetchFormResult()
      }
    },
  },
};
</script>
